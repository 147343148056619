<template>
  <v-row class="pt-8"
    align="center"
    justify="center">
    <v-col cols="6"
      class="text-center">
      <v-progress-circular :size="70"
        :width="7"
        color="primary"
        indeterminate />
    </v-col>
    <v-col cols="12"
      class="text-center">
      <span class="title">{{ $t('loading') }}</span>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Loading',
  };
</script>
